import React from "react"
import { Helmet } from "react-helmet"
import config from "../../site.config"

/** components */
import Layout from "../components/Layout"
import { theme } from "../components/Theme"

/** dynamic sections */
import HeroTopSection from "../components/dynamic-sections/HeroTopSection"
import RibbonSection from "../components/dynamic-sections/RibbonSection"
import HeroRightSection from "../components/dynamic-sections/HeroRightSection"
import SixImageSection from "../components/dynamic-sections/SixImageSection"
import GalleryImageSection from "../components/dynamic-sections/GalleryImageSection"
import AwardsSection from "../components/dynamic-sections/AwardsSection"
import SixBoxSection from "../components/dynamic-sections/SixBoxSection"
import HeroBottomSection from "../components/dynamic-sections/HeroBottomSection"
import HeroMiddleSection from "../components/dynamic-sections/HeroMiddleSection"

/** svg */
import NorhartIcon from "../../assets/norhart-icon.svg"
import ApartmentIcon from "../../assets/apartment-icon.svg"
import ValuesIcon from "../../assets/values.svg"
import NorhartLeanIcon from "../../assets/value-norhart-logo.svg"
import LeanOneIcon from "../../assets/lean-1.svg"
import LeanTwoIcon from "../../assets/lean-2.svg"
import LeanThreeIcon from "../../assets/lean-3.svg"
import LeanFourIcon from "../../assets/lean-4.svg"
import LeanFiveIcon from "../../assets/lean-5.svg"
import NorhartDarkCircleIcon from "../../assets/norhart-dark-circle-icon.svg"

/** color theme */
const colorPalette = theme.colorPalettes.blue

interface Props {
  data: any
}

/** const */
const ConstructionProcessPage: React.FC<Props> = (props) => {
  const ldJson = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        item: {
          "@id": "/process/",
          name: "Lean Construction Process | Norhart",
          image: `${config.siteMetadata.siteUrl}/process/norhart-process-hero-open-graph.png`,
        },
      },
    ],
  }

  return (
    <Layout
      title="Lean Construction Process | Norhart"
      description="Norhart's lean construction process creates stunning luxury apartments and living experiences in Forest Lake, Blaine, Circle Pines, Lexington, and Oakdale, Minnesota."
      keywords="norhart, luxury, innovative, smart, technology, apartment, apartments, building, buildings, mn, minnesota, minneapolis, st paul, rent, rentals, resident, residents, forest lake, blaine, circle pines, lexington, coon rapids, Oakdale, encore apartments, lexington lofts apartments, Oakdale apartments, gateway green apartments, mill pond apartments, birchview apartments, legacy pointe apartments, heritage heights apartments, springwood apartments, greystone apartments, northbrook apartments, construction, norhart construction, lean construction"
      imageTwitter={`${config.siteMetadata.siteUrl}/labs/design-process/norhart-design-process-hero-twitter-card.png`}
      imageOpenGraph={`${config.siteMetadata.siteUrl}/process/norhart-process-hero-open-graph.png`}
      colorPalette={colorPalette}
    >
      <HeroTopSection
        title="Lean Culture"
        subtitle="Design. Build. Improve."
        imageTitle="Norhart Lean Constrution Process and Mindset"
        image="/process/norhart-process-hero.png"
        colorPalette={colorPalette}
      />

      <RibbonSection
        title="Norhart Builds Apartments"
        tagLine="Apartments in the Minneapolis metro area"
        colorPalette={colorPalette}
      />

      <Helmet>
        <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
      </Helmet>

      <SixImageSection
        backgroundColorOne="#EFF2F4"
        backgroundColorTwo="#EFF2F4"
        fontTextColor="#365c70"
        icon={NorhartDarkCircleIcon}
        iconTitle="Norhart Process"
        title="Our Lean Process"
        subtitle="We design and build incredible apartment buildings in Forest Lake, Blaine, and Oakdale Minnesota. We truly are creating a better way for people to live one building at a time. Thanks, Norhart!"
        imageTitleOne="Market Research"
        imageOneText="We always begin all of our exciting construction projects with research, due diligence, and market analysis. We engage in this process to ensure a good fit for us, the area, and our future residences. Even if this sounds boring, it's an essential part of the process."
        imageOne="/process/norhart-process-market-research.webp"
        imageOneAlt="Norhart Market Research"
        imageOnePill="Phase 1"
        imageTitleTwo="Design To Blueprint"
        imageTwoText="The design phase is exciting and full of possibilities. Now that we have our market data, we can take all our ideas and develop something exceptional. We take our time and think about every little detail and how our design will enhance our future residence's living experience."
        imageTwo="/process/norhart-process-blueprint.webp"
        imageTwoAlt="Norhart Design To Blueprint"
        imageTwoPill="Phase 2"
        imageTitleThree="Establish Timelines"
        imageThreeText="Now that we have our blueprint, we need to establish timelines. There are lots of moving parts when building an apartment building. So we need to make sure we take advantage of optimizing our process with methodologies such as Lean Six Sigma to make sure we stay on track and budget."
        imageThree="/process/norhart-process-timeline.webp"
        imageThreeAlt="Norhart Establish Timelines"
        imageThreePill="Phase 3"
        imageTitleFour="Break Ground and Build"
        imageFourText="Yes! It's time to take our designs, pour the foundation, and bring our vision to life! This process involves our highly skilled and cohesive team of architects, engineers, and construction crews working in unison and harmony to build your future home."
        imageFour="/process/norhart-process-break-ground.webp"
        imageFourAlt="Norhart Break Ground and Build"
        imageFourPill="Phase 4"
        imageTitleFive="Fit and Finish"
        imageFiveText={`Once initial construction is completed we go through a process called "Fit and Finish." We go over every little detail carefully with a fine-tooth comb to ensure build quality is exceeding our high expectations. If something does not look right or feel right, we fix it during this phase.`}
        imageFive="/process/norhart-process-fit-and-finish.webp"
        imageFiveAlt="Norhart Fit and Finish"
        imageFivePill="Phase 5"
        imageTitleSix="Happy Residents"
        imageSixText="The final phase is our favorite! We love to see and hear about how we are creating an exceptional living experience for our residences. We are delighted to receive feedback and use that information to improve and refine our processes. We are all about continuous process improvements!"
        imageSix="/process/norhart-process-happy-residence.webp"
        imageSixAlt="Norhart Happy Residences"
        imageSixPill="Phase 6"
        pillActive="Active"
        colorPalette={colorPalette}
        buttonBackground={colorPalette.heroTop}
        buttonText="#FFFFFF"
      />

      <GalleryImageSection
        backgroundColorOne={colorPalette.cardAccentTop}
        backgroundColorTwo={colorPalette.cardAccentTop}
        icon={ApartmentIcon}
        iconTitle="Nohart Apartments"
        title="Current Project"
        subtitle={`We invite you to check out our latest project "Lexington Lofts" in Blaine, MN. At Norhart our teams have designed and built an exceptional living experience from the top down!`}
        imageTitleOne="Lexington Lofts Image 1"
        imageOne="/apartments/gallery/lexington-lofts/norhart-lexington-lofts-1.webp"
        imageTitleTwo="Lexington Lofts Image 2"
        imageTwo="/apartments/gallery/lexington-lofts/norhart-lexington-lofts-2.webp"
        imageTitleThree="Lexington Lofts Image 3"
        imageThree="/apartments/gallery/lexington-lofts/norhart-lexington-lofts-3.webp"
        imageTitleFour="Lexington Lofts Image 4"
        imageFour="/apartments/gallery/lexington-lofts/norhart-lexington-lofts-4.webp"
        imageTitleFive="Lexington Lofts Image 5"
        imageFive="/apartments/gallery/lexington-lofts/norhart-lexington-lofts-5.webp"
        imageTitleSix="Lexington Lofts Image 6"
        imageSix="/apartments/gallery/lexington-lofts/norhart-lexington-lofts-6.webp"
        colorPalette={colorPalette}
        badge={false}
      />

      <SixBoxSection
        backgroundColorOne="#EFF2F4"
        backgroundColorTwo="#EFF2F4"
        icon={ValuesIcon}
        title="Norhart's Lean Methodology"
        subtitle="At Norhart, our strategy and school of thought is to design exceptional apartments through continuous improvement and minimal waste while delivering maximum value to our customers. #GreatPlan"
        boxIconOne={NorhartLeanIcon}
        boxTitleOne="Norhart"
        boxSubTitleOne="Lean Is The Way"
        boxDescriptionOne="At Norhart, our processes help us decrease time, effort, and waste. It also ensures our projects are done at a lower cost and on time while maintaining exceptional build quality."
        boxIconTwo={LeanOneIcon}
        boxTitleTwo="Lean"
        boxSubTitleTwo="Define Value"
        boxDescriptionTwo="Norhart defines value based on what the customer wants. We love to build exceptional apartments that exceed our customer's expectations and allow us to create a better product with less waste."
        boxIconThree={LeanTwoIcon}
        boxTitleThree="Lean"
        boxSubTitleThree="Map Value Streams"
        boxDescriptionThree="Norhart is an entirely data-driven organization. We use that intelligence to produce successful outcomes over and over. We foster and encourage this trait and mindset in all our projects and people."
        boxIconFour={LeanThreeIcon}
        boxTitleFour="Value"
        boxSubTitleFour="Create Flow"
        boxDescriptionFour="Norhart's tight-focused construction teams create controlled processes that dramatically improve our construction development time while maintaining a top-notch build quality end-to-end."
        boxIconFive={LeanFourIcon}
        boxTitleFive="Value"
        boxSubTitleFive="Establish Pull"
        boxDescriptionFive="Norhart's processes ensure a smooth workflow by making sure that nothing is made ahead of time; we are constantly working toward just-in-time construction to ensure maximum value."
        boxIconSix={LeanFiveIcon}
        boxTitleSix="Value"
        boxSubTitleSix="Pursuit of Perfection"
        boxDescriptionSix="Norhart's teams love continuous process improvement. Our teams are constantly working towards perfection. We continually refine our processes to produce better results over time."
        colorPalette={colorPalette}
      />

      <HeroBottomSection
        backgroundColorOne={colorPalette.cardAccentTop}
        backgroundColorTwo={colorPalette.cardAccentTop}
        icon={NorhartDarkCircleIcon}
        iconTitle="Methodology"
        title="Lean Is The Way!"
        subTitle="At Norhart, our processes helps us decrease time, effort, and waste. It also ensures our projects are done at a lower cost and on time while maintaining exceptional build quality."
        image="/process/norhart-process-lean-is-the-way.gif"
        imageAlt="Norhart Support Team"
        textColor={colorPalette.textColor}
        asoEffect="zoom-in"
      />

      <HeroMiddleSection
        imageTitle="Norhart Lean Construction"
        image="/process/norhart-process-lean-construction.png"
        colorPalette={colorPalette}
      />

      <HeroRightSection
        backgroundColorOne={colorPalette.heroTop}
        backgroundColorTwo={colorPalette.heroBottom}
        fontTextColor="#FFFFFF"
        titleTopLeft="Career Opportunities"
        badgeTitle="Now Hiring"
        title="Join Our A-Team"
        subtitle="Built into Norhart's DNA is the obsession to improve our customer's lives. We love elevating our people's skills, creativity, and drive to build the exceptional."
        imageTitle="Norhart Technology"
        image="/process/norhart-careers-join-our-construction-team.png"
        buttonName="Learn More"
        buttonUrl="/careers/"
        colorPalette={colorPalette}
        colSizeOne={4}
        colSizeTwo={8}
        bottomMargin={0}
        asoEffect="fade-in"
      />

      <AwardsSection
        header="Norhart Apartments"
        title="We Build Award Winning Apartments"
        subtitle="We have been called out for being an innovator, setting the bar high, and pushing the industry in a new direction. That's just who we are. Experience the Norhart difference for yourself!"
        colorPalette={colorPalette}
      />
    </Layout>
  )
}

/** export */
/** export */
export default ConstructionProcessPage
